import React, { useEffect, useState } from "react";
import { ArrowLogo, IconLogo } from "../../icons";
import { useScrollTrigger } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  checkAuthTokenAvailability,
  getAuthData,
  removeAuth,
} from "../../util/cookieReducer";
import {
  setIsToken,
  setUserData,
  signInSelector,
} from "../../features/signIn/signInSlice";
import { removeCardsData } from "../../features/savedCards/savedCardsSlice";
import {
  resetPaymentForm,
  setOpenPayInfo,
  setUserProfileNotFoundGPay,
} from "../../features/costSummary/costSummarySlice";
import {
  setCollapseEnroll,
  setIsChecked,
  setIsCreateNewUser,
} from "../../features/enroll/enrollSlice";
import { Abtest } from "../../mixpanel";
import { meetingSliceActions } from "../../features/meeting/api/slice";
import HotelModal from "../../features/hotelModal";
import { stripeSelector } from "../../features/stripe/stripeSlice";
import { useMemo } from "react";

function Header({ className, allHotels }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });
  const [isOpen, setOpen] = useState(false);
  // webflowWebView
  const isWebflowWebView = window.location.pathname.includes("webflowWebView");
  console.log("isWebflowWebView:", isWebflowWebView);


const isWebflowTnpa = window.location.pathname.includes("WebflowTnpa");
console.log("isWebflowTnpa:", isWebflowTnpa);

  const arrayHaveValue = useMemo(() => {
    if (Array.isArray(allHotels) && allHotels?.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [allHotels]);

  const HeaderCom = () => {
    const { isToken, userData, removeLogoutOption } =
      useSelector(signInSelector);
    const { hotelDetails } = useSelector(stripeSelector);
    const dispatch = useDispatch();

    // useEffect(() => {
    //   dispatch(setIsToken(checkAuthTokenAvailability()));
    //   dispatch(setUserData(getAuthData()));
    // }, [dispatch]);

    const handleLogout = () => {
      dispatch(
        setUserData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNoWithoutCountryCode: "",
        })
      );
      dispatch(
        removeCardsData({
          isSavedCards: false,
          savedCards: [],
          selectedCard: {},
        })
      );
      removeAuth();
      dispatch(setIsToken(false));
      dispatch(setUserData({}));
      dispatch(resetPaymentForm());
      dispatch(setUserProfileNotFoundGPay(false));
      dispatch(setOpenPayInfo(false));
      dispatch(setIsChecked(false));
      dispatch(setIsCreateNewUser(false));
      dispatch(setCollapseEnroll(false));
    };

    const handleLogin = () => {
      dispatch(meetingSliceActions.openSignInModal());
    };

    return (
      <motion.header
        // initial={{ opacity: 0, top: -60 }}
        // animate={{ opacity: 1, top: 0 }}
        // style={{
        //   backdropFilter:'blur(5px)',
        //   backgroundColor: trigger ? "#ffffff5c" : "white",
        //   color: trigger ? "white" : "black",
        // }}
        // transition={{ duration: 1.2 }}
        className={`h-[72px] lg:h-[72px] shadow-md 
        bg-main flex items-center 
        ml-auto mr-auto  
        top-0 z-40 w-full px-5 md:px-[50px] lg:px-[80px]`}
      >
        <div
          className={`w-full mx-auto max-w-[86rem] flex justify-between items-center `}
        >
          <Abtest
            VariantA={
              <a className={``} href="https://shashihotel.com/">
                <img src={hotelDetails?.hotelLogoBlack} alt="" />
              </a>
            }
            VariantB={
              <div
                onClick={() => {
                  if (!removeLogoutOption) return;
                  setOpen(true);
                }}
                className={`ml-auto mr-auto flex flex-wrap items-center gap-2  cursor-pointer`}
              >
                <div>
                  <img width={100} src={hotelDetails?.hotelLogoBlack} alt="" />
                </div>
                {arrayHaveValue && removeLogoutOption && <ArrowLogo />}
              </div>
            }
          />
          {userData?.email ? (
            <button
              className={`text-black hover:text-magenta filter-brightness-150 ${
                removeLogoutOption ? `hidden` : ""
              }`}
              onClick={handleLogout}
            >
              Logout
            </button>
          ) : (
            window.location.pathname.includes("/meeting") && (
              <button
                className="text-black hover:text-magenta filter-brightness-150"
                onClick={handleLogin}
              >
                Login
              </button>
            )
          )}
        </div>
        {arrayHaveValue && (
          <HotelModal
            allHotels={allHotels}
            handleClose={() => {
              setOpen(false);
            }}
            isOpen={isOpen}
          />
        )}
      </motion.header>
    );
  };

  return (
    <AnimatePresence>
      {isWebflowWebView || isWebflowTnpa ? null : trigger ? <HeaderCom /> : <HeaderCom />}
    </AnimatePresence>
  );
}

export default Header;
