import { meetingApis_ } from "./api";

export const extraReducers = (builder) => {
  builder.addCase(meetingApis_.createEvent.pending, (state, action) => {
    state.createApiLoader = true;
    console.log("pending", action.payload);
  });
  builder.addCase(meetingApis_.createEvent.fulfilled, (state, action) => {
    console.log("fulfilled", action.payload);
    state.createApiLoader = false;
    state.isOpenModal = false;
    state.selectedSlot.start = "";
    state.selectedSlot.end = "";
    state.selectedSlot.title = "";
  });

  builder.addCase(meetingApis_.createEvent.rejected, (state, action) => {
    console.log("rejected", action.payload);
    state.createApiLoader = false;
    state.isOpenModal = false;
    state.selectedSlot.start = "";
    state.selectedSlot.end = "";
    state.selectedSlot.title = "";
  });

  builder.addCase(meetingApis_.findEvents.pending, (state, action) => {
    state.createApiLoader = false;
    state.fetchApiLoader = true;
    state.eventList = []
    state.isOpenModal = false;
    console.log("pending", action.payload);
  });
  builder.addCase(meetingApis_.findEvents.fulfilled, (state, { payload }) => {
    state.createApiLoader = false;
    state.fetchApiLoader = false;
    state.isOpenModal = false;
    console.log("fulfilled", payload);
    state.eventList = [...payload?.data?.items,...payload?.data?.currentUserEvents] || [];
  });
  builder.addCase(meetingApis_.findEvents.rejected, (state, action) => {
    state.createApiLoader = false;
    state.fetchApiLoader = false;
    state.isOpenModal = false;
    console.log("rejected", action.payload);
  });

  builder.addCase(meetingApis_.findCalendarChannel.pending, (state, action) => {
    console.log("pending", action.payload);
  });
  builder.addCase(
    meetingApis_.findCalendarChannel.fulfilled,
    (state, { payload }) => {
      console.log("fulfilled", payload);
      state.calendarChannels = payload?.data || [];
    }
  );
  builder.addCase(
    meetingApis_.findCalendarChannel.rejected,
    (state, action) => {
      console.log("rejected", action.payload);
    }
  );
};
