export const reducers = {
  closeModal: (state) => {
    state.isOpenModal = false;
    state.selectedSlot.start = "";
    state.selectedSlot.end = "";
    state.selectedSlot.title = "";
  },
  selectSlot: (state, { payload }) => {
    state.isOpenModal = true;
    state.selectedSlot.start = payload.start;
    state.selectedSlot.end = payload.end;
  },
  setPresentCalendarDate: (state, { payload }) => {
    state.presentCalendarDate = payload || "";
  },
  setMeetingCategory: (state, { payload }) => {
    state.meetingCategory = payload || "";
    state.eventList = [];
  },
  openSignInModal: (state, { payload }) => {
    state.isOpenModal = true;
  },
};
