import moment from "moment";

export const initialState = {
  isOpenModal: false,
  selectedSlot: { start: "", end: "",userName:"" },
  createApiLoader: false,
  fetchApiLoader:false,
  eventList: [],
  presentCalendarDate: "",
  calendarChannels: [],
  meetingCategory:'MP'
};
