import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { meetingSliceActions, meetingSelector } from "./api/slice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
function BigCalendar() {
  const localizer = momentLocalizer(moment);
  moment.tz.setDefault("America/Los_Angeles");
  const dispatch = useDispatch();
  const meetingStates = useSelector(meetingSelector);
  const [currentView, setCurrentView] = useState();

  const handleSelectEvent = () => {
    // alert("event selected");
  };

  const handleNavigate = (date, view, action) => {
    dispatch(
      meetingSliceActions.setPresentCalendarDate(moment(date).toISOString())
    );
  };

  const isOnlyContainsDate = (time, timezone) => {
    let Date = moment(time);
    if (timezone) {
      return Date.toDate();
    } else {
      return Date.startOf("day").toDate();
    }
  };

  let Events = [];
  if (meetingStates?.eventList.length > 0) {
    Events = meetingStates?.eventList.map((e) => {
      return {
        private: e?.extendedProperties?.private || {},

        title: (
          <section>
            <div className="text-[9px] flex justify-between">
              {`${moment(e.start?.dateTime).format("hh:mm A")} -  ${moment(
                e.end?.dateTime
              ).format("hh:mm A")}`}
              <div className="mr-0 text-[#bdff9e]">
              {e?.isPrivate ? "My Booking" : ""}
              </div>
            </div>
            <div className="mt-[1px]">
              {e.title || e.summary || `(No title)`}
              
            </div>
          </section>
        ),
        start: isOnlyContainsDate(
          e.start?.dateTime || e.start?.date,
          e.start?.timeZone
        ),
        end: isOnlyContainsDate(
          e.end?.dateTime || e.end?.date,
          e.end?.timeZone
        ),
        allDay: false,
        isPrivate: e.isPrivate || false,
        background: e.isPrivate
          ? "#ff5891"
          : meetingStates.meetingCategory == "N/S"
          ? "#7886cb"
          : meetingStates.meetingCategory == "NS"
          ? "#e67c73"
          : "#009688",
      };
    });
  } else {
    Events = [];
  }

  const handleSelectSlot = ({ start, end, slots, action, resourceId }) => {
    if (currentView != "day" && currentView != "week") return;
    if (moment(start).format("DD-MM-YYYY") != moment(end).format("DD-MM-YYYY"))
      return;

    const isAlreadyBooked = Events.filter((slot) => {
      if (
        ((start >= slot.start && start < slot.end) ||
          (end > slot.start && end <= slot.end) ||
          (start <= slot.start && end >= slot.end)) &&
        !slot.isPrivate
      ) {
        return slot;
      }
    });

    if (isAlreadyBooked.length > 0) {
      alert("Selected slot is already booked");
      return;
    }

    if (moment(start).format("X") < moment().add(30, "minute").format("X")) {
      alert(`Book at least 30 minutes before your desired time`);
      return;
    }
    start = moment(start).toISOString();
    end = moment(end).toISOString();
    dispatch(meetingSliceActions.selectSlot({ start, end }));
  };

  const slotGroupPropGetter = useCallback(
    () => ({
      style: {
        minHeight: 72,
      },
    }),
    []
  );

  // const slotPropGetter = useCallback(
  //   () => ({
  //     style: {
  //       background: '#e1e1e1',
  //     },
  //   }),
  //   []
  // );

  const eventPropGetter = (event) => {
    const backgroundColor = event.background;
    return {
      style: { backgroundColor, fontSize: "11px" },
    };
  };

  const dayPropGetter = useCallback((date) => {
    if (moment(date).isBefore(moment().startOf("day"))) {
      return {
        style: { background: "#95959563", zIndex: "6", pointerEvents: "none" },
      };
    } else if (moment(date).isSame(moment().startOf("day"))) {
      return { style: { background: "#3eac5c75" } };
    } else if (moment(date).isAfter(moment().add(3, "months").startOf("day"))) {
      return {
        style: { background: "#95959563", zIndex: "6", pointerEvents: "none" },
      };
    } else {
      return { style: { background: "white" } };
    }
  }, []);

  const timeGutterFormat = (date, culture, localizer) => {
    if (meetingStates.meetingCategory == "MP") {
      return localizer.format(date, "h:mm A", culture);
    } else {
      return "Full Day";
    }
  };
  return (
    <div className="react-big-calendar-baseDiv px-1 md:px-10 w-full mt-5 md:mt-10 relative">
      <Calendar
        onNavigate={handleNavigate}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        defaultView="month"
        selectable={true}
        localizer={localizer}
        onView={(e) => setCurrentView(e)}
        startAccessor="start"
        endAccessor="end"
        style={{ width: "100%" }}
        timeslots={meetingStates.meetingCategory == "MP" ? 4 : 1}
        step={meetingStates.meetingCategory == "MP" ? 15 : 1440}
        events={Events}
        // slotPropGetter={slotPropGetter}
        dayPropGetter={dayPropGetter}
        slotGroupPropGetter={slotGroupPropGetter}
        formats={{ timeGutterFormat: timeGutterFormat,eventTimeRangeFormat:()=>"" }}
        eventPropGetter={eventPropGetter}
      />

      {meetingStates.fetchApiLoader && (
        <div
          className=" backdrop-blur-sm top-0"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#9c9c9c1a",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="flex justify-center">
            <Bars
              height="50"
              width="50"
              color="#ff0094"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BigCalendar;
