import React, { useEffect } from "react";
import App from "./App";
import "./index.css";
import Layout from "./layout";
import Loader from "./layout/Loader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Err404,
  IntroPage,
  PasswordReset,
  RootPage,
  VerifyUser,
} from "./pages";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogoutOption } from "./features/signIn/signInSlice";
import { mixPanelInit } from "./mixpanel";
import { emitter } from "@marvelapp/react-ab-test";
import { stripeAction } from "./features/stripe/stripeSlice";

function BaseRouteWrapper({ triggerMixpanelInitialVisit }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setIsLogoutOption(
        new URLSearchParams(window.location.search).get("device") == "mobile"
          ? true
          : false
      )
    );
    dispatch(
      stripeAction.setIsAndroid(
        new URLSearchParams(window.location.search).get("os") == "android"
          ? true
          : false
      )
    );
    mixPanelInit(triggerMixpanelInitialVisit);
    emitter.defineVariants("bookingEngine", ["A", "B"]);
  }, []);

  return (
    <Layout>
      <Loader />
      <BrowserRouter>
        <Routes>
          <Route path="/intro" element={<IntroPage />} />
          <Route path="/*" element={<RootPage />} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default BaseRouteWrapper;
