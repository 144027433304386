import { styled } from "@mui/system";
import React, { Fragment } from "react";
import { SkipLink } from "../commonElements";
import Header from "./Header";
import { useSelector } from "react-redux";
import { signInSelector } from "../features/signIn/signInSlice";
import { useEffect } from "react";
import { useState } from "react";
import { AXIOS_RESERVATION } from "../util/axiosInterceptors";

function Layout({ children }) {
  const Main = styled("main")({});
  const [allHotels, setAllHotels] = useState([])
  
  
  const fetchHotelDetails = async () => {
    try {
      let respose = await AXIOS_RESERVATION.get("getAllHotels")
      setAllHotels(respose?.data?.result?.data||[])
   } catch (error) {
    setAllHotels([])
   }
  }
  
  useEffect(() => {
    fetchHotelDetails()
  }, [])

  return (
    <Fragment>
      {/* IF HEADER AND MAIN COMPONENT HEIGHT CHANGE h-[58px]. 
      SAME TIME NEED TO CHANGE AVAILABLE ROOM COMPONENT PADDING TOP */}
      <SkipLink/>
      <Header allHotels={allHotels} />
      <Main
        sx={{minHeight:`calc(100vh - 72px)`}}
        className="lg:pb-[58px] bg-white lg:bg-main  flex"
        children={children}
      />
    </Fragment>
  );
}

export default Layout;
