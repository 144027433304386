import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
import { extraReducers } from "./extraReducers";

const slice = createSlice({
  initialState,
  name: "meetingSlice",
  reducers,
  extraReducers,
});
export const meetingSliceActions = slice.actions
export const meetingSelector = (state)=>state.meeting
export default slice.reducer