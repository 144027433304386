import React, { Fragment, useEffect, useState } from "react";
import BigCalendar from "./calendar";
import Modal from "./modal";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { meetingApis_ } from "./api/api";
import { config } from "../../config";
import { meetingSelector } from "./api/slice";
import { signInSelector } from "../signIn/signInSlice";
const ackTimeout = 5000;

function MeetingSection() {
  const dispatch = useDispatch();
  const { meetingCategory } = useSelector(meetingSelector)
  const {userData} = useSelector(signInSelector)
  const [triggerApiAgain, setTriggerApiAgain] = useState(false);
  
  useEffect(() => {
    let EndPoint = config.RESERVATION_API.split("/api");
    if (EndPoint.length > 1) {
      const socket = io(EndPoint[0], { ackTimeout });

      const connectSocket = () => {
        console.log(socket.id, "Socket Connected");
      };

      const calendarHook = () => {
        setTriggerApiAgain((e) => !e);
      };

      socket.on("connect", connectSocket);
      socket.on("calendarHook", calendarHook);

      return () => {
        socket.off("connect", connectSocket);
        socket.off("calendarHook", calendarHook);
        socket.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    dispatch(meetingApis_.findEvents());
  }, [triggerApiAgain,meetingCategory,userData?.email]);

  return (
    <Fragment>
      <BigCalendar />
      <Modal />
    </Fragment>
  );
}

export default MeetingSection;
