import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../util/axiosInterceptors";

const createEvent = createAsyncThunk(
  "meeting/createEvent",
  async (state, { rejectWithValue, getState }) => {
    try {
      const { signIn, meeting } = getState();
      let body = { ...state };
      let userData = signIn?.userData;
      if (!meeting.calendarChannels.length > 0) {
        throw { response: { data: { message: "Channel not found" } } };
      }
      let calendarId = meeting.calendarChannels[0].calendarId;
      // if (userData?.firstName && userData?.lastName) {
      //   body.userName = `${userData?.firstName} ${userData?.lastName}`;
      // } else if (userData?.firstName && !userData?.lastName) {
      //   body.userName = userData?.firstName;
      // }

      if (userData.email) {
        body.email = userData.email;
        let res = await AXIOS_RESERVATION.post(
          "/google-calendar-events/insert-event",
          body,
          { params: { calendarId, meetingCategory: meeting.meetingCategory } }
        );
        return res.data;
      } else {
        throw { response: { data: { message: "User Email Not Found" } } };
      }
    } catch (error) {
      console.log(error?.response?.data, "error?.res?.data");
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message);
      }
      throw rejectWithValue("");
    }
  }
);

const findEvents = createAsyncThunk(
  "meeting/findEvent",
  async (state, { rejectWithValue, getState }) => {
    try {
      const { meeting, signIn } = getState();
      let userData = signIn?.userData;
      if (!meeting.calendarChannels.length > 0) {
        throw { response: { data: { message: "Channel not found" } } };
      }

      let calendarId = meeting.calendarChannels[0].calendarId;
      let res = await AXIOS_RESERVATION.get(
        "/google-calendar-events/get-event",
        {
          params: {
            calendarId,
            meetingCategory: meeting.meetingCategory,
            email: userData?.email || "",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error?.res?.data, "error?.res?.data");
      throw rejectWithValue("");
    }
  }
);

const findCalendarChannel = createAsyncThunk(
  "meeting/findCalendarChannel",
  async (state, { rejectWithValue, getState }) => {
    try {
      let res = await AXIOS_RESERVATION.get(
        "/google-calendar-events/calendar-channels"
      );
      return res.data;
    } catch (error) {
      console.log(error?.res?.data, "error?.res?.data");
      throw rejectWithValue("");
    }
  }
);

export const meetingApis_ = {
  createEvent,
  findEvents,
  findCalendarChannel,
};
