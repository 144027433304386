import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import MaterialTheme from "./theme";
import React from "react";

import BaseRouteWrapper from "./baseRouteWrapper";
const container = document.getElementById("root");
var triggerMixpanelInitialVisit = false;
const root = createRoot(container);
console.log(document.referrer, "document.referrer");


const isWebflowWebView = window.location.pathname.includes("webflowWebView");
console.log("isWebflowWebView:", isWebflowWebView);


const isWebflowTnpa = window.location.pathname.includes("WebflowTnpa");
console.log("isWebflowTnpa:", isWebflowTnpa);

if ((!isWebflowWebView || !isWebflowTnpa) && !window.localStorage.getItem("PUSHTELL-bookingEngine")) {
  triggerMixpanelInitialVisit = true;
  console.log("index", typeof window.localStorage);
}


root.render(
  <Provider store={store}>
    <MaterialTheme>
      <BaseRouteWrapper
        triggerMixpanelInitialVisit={triggerMixpanelInitialVisit}
      />
    </MaterialTheme>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
