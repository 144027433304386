import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { meetingSliceActions, meetingSelector } from "./api/slice";
import { Button } from "../../commonElements";
import { ThreeDots } from "react-loader-spinner";
import { useFormik } from "formik";
import { meetingApis_ } from "./api/api";
import { signInSelector } from "../signIn/signInSlice";
import SignIn from "../signIn";
import moment from "moment";
function CustomModal() {
  const { isOpenModal, selectedSlot, createApiLoader } =
    useSelector(meetingSelector);
  const authSelector = useSelector(signInSelector);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(meetingSliceActions.closeModal());

  const handleSubmitForm = (values) => {
    dispatch(meetingApis_.createEvent(values));
  };

  const formik = useFormik({
    initialValues: selectedSlot,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  return (
    <Modal open={isOpenModal} onClose={handleClose}>
      {!authSelector?.userData?.email ? (
        <section className="mx-auto w-[600px] mt-[200px] ">
          <SignIn handleClose={handleClose} />
        </section>
      ) : (
        <form
          className="w-[600px] h-[190px] outline-none mt-[200px] bg-white p-3  md:p-6  mx-auto"
          onSubmit={formik.handleSubmit}
        >
          <div className="text-xs text-center mb-2">{`${moment(selectedSlot?.start).format(
            "h:mm A"
          )} to ${moment(selectedSlot?.end).format("h:mm A")}`}</div>
          <section className="w-full h-full flex flex-col rounded-md">
            <input
              value={formik.values.userName}
              name="userName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="User Name"
              type="text"
              className=" outline-none shadow-xl px-2 h-[45px] w-[100%] border border-[#f3f1f1]"
            />
            {/* <textarea
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Description"
            className="resize-none outline-none w-[100%] h-40 shadow-xl p-2 mt-3  border border-[#e8e3df]"
            name="description"
            value={formik.values.description}
          /> */}
            <div className="w-[200px] mx-auto mt-8">
              <Button
                type={"submit"}
                spanClassName={"font-normal"}
                name={!createApiLoader && "Submit"}
                className={"text-white bg-black rounded-sm"}
                loadingThreeDots={
                  createApiLoader && (
                    <ThreeDots
                      height="24"
                      width="24"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )
                }
              />
            </div>
          </section>
        </form>
      )}
    </Modal>
  );
}

export default CustomModal;
