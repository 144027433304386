import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import countryList from "react-select-country-list";
import { payWithCardValidationSchema } from "../../commonElements/validationSchema";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PatternFormat } from "react-number-format";
import { Input } from "../../commonElements/input/cardInput";
import { ErrorMsg } from "../../commonElements/input/cardInput";
import { useDispatch, useSelector } from "react-redux";
import { makePayment, makeTokenization } from "./api";
import { Collapse } from "@mui/material";
import moment from "moment";
import { Button, SetFormField } from "../../commonElements";
import {
  costSummarySelector,
  setGuestInfo,
  setIsChecked,
  setStatus,
} from "./costSummarySlice";
import { ThreeDots } from "react-loader-spinner";
import { Swal } from "../../components";
import { signInSelector } from "../signIn/signInSlice";
import { useNavigate, useParams } from "react-router-dom";
import { saveCardsSelector } from "../savedCards/savedCardsSlice";
import SavedCards from "../savedCards";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { calendarSelector } from "../calender/calenderSlice";
import { roomSelector } from "../rooms/roomSlice";
import { RatePlan_Category } from "../../config";
import { mixPanelEvents } from "../../mixpanel";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "42px",
    borderRadius: "0",
    border: "1px solid #9CA3AF",
    paddingLeft: "8px",
    paddingRight: "8px",
  }),
};

const loadingThreeDots = () => (
  <ThreeDots
    height="24"
    width="24"
    radius="9"
    color="white"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
  />
);

const PayWithCard = ({ open, totalTax, UpdatedTaxArary, queryDetails }) => {
  const [expiryDate, setExpiryDate] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [isCvvVisible, setIsCvvVisible] = useState(false);
  const {
    loading,
    status,
    isChecked,
    reservationStatus,
    reservationRespData,
    totalCost,
    costSItems,
    formFieldInitialState,
    isUsrProfileNotFoundGpay,
  } = useSelector(costSummarySelector);
  const { userData, removeLogoutOption } = useSelector(signInSelector);
  const { isDateSingleSelect } = useSelector(calendarSelector);
  const roomState = useSelector(roomSelector);
  const {
    isSavedCards,
    addNewCard,
    selectedCard,
    isCardSelected,
    openCardOptions,
  } = useSelector(saveCardsSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const countryCodeForMobileNumberChangeHandler = (
    countryCode,
    setFieldValue
  ) => {
    setFieldValue("countryCodeForMobileNumber", `+${countryCode}`);
  };

  const expiryDateChangeHandler = (date, setFieldValue) => {
    setExpiryDate(date);
    setFieldValue("expiryDate", moment(date).format("MM/YY"));
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let updateValue = {
      ...values,
    };
    if (
      roomState.selectedRatePlan?.ratePlanCategory == RatePlan_Category?.corp &&
      roomState.selectedRatePlan?.ratePlanCode == ""
    ) {
      dispatch(
        setStatus({
          apiMessage: "Please provide the Rate Plan details to proceed with your reservation.",
          apiSuccess: false,
        })
      );
      return;
    }

    if (!roomState.isRoomAvailable) {
      console.log(roomState.status);
      dispatch(
        setStatus({
          apiMessage:
            roomState?.status?.apiMessage ||
            "The hotel is unavailable for the dates selected.",
          apiSuccess: false,
        })
      );
      return;
    }

    if (isChecked && !isDateSingleSelect) {
      updateValue.mobileNoWithoutCountryCode =
        updateValue.mobileNoWithoutCountryCode.replace(/-/g, "");
      updateValue.card = values.card.replace(/-/g, "");
      updateValue.phoneNo = `${values.countryCodeForMobileNumber}${values.mobileNoWithoutCountryCode}`;

      console.log(updateValue, "setSubmitting");
      if (isSavedCards && isCardSelected) {
        const {
          firstName,
          lastName,
          email,
          countryCodeForMobileNumber,
          mobileNoWithoutCountryCode,
          phoneNo,
        } = updateValue;

        const tokenizedData = {
          card: "",
          currency: "usd",
          customer: "",
          description: "Reservation",
          source: selectedCard?.token,
          sourceXml: "",
          email: email,
          isPrefered: true,
          amount: parseFloat(totalCost),
          queryDetails,
        };

        dispatch(
          setGuestInfo({
            firstName,
            lastName,
            email,
            countryCodeForMobileNumber,
            mobileNoWithoutCountryCode,
            phoneNo,
          })
        );
        dispatch(makePayment(tokenizedData));
      } else {
        dispatch(makeTokenization({ ...updateValue, queryDetails }));
      }

      setTimeout(() => {
        setSubmitting(false);
        resetForm({ values: "" });
        setExpiryDate("");
        // setIsChecked(!isChecked);
      }, 400);
    } else {
      dispatch(
        setStatus({
          apiMessage: isDateSingleSelect
            ? "Your Arrival and Departure date cannot be same."
            : "Please review and agree to the Terms & Conditions.",
          apiSuccess: false,
        })
      );
    }
  };

  useEffect(() => {
    if (reservationStatus) {
      const {
        reservationData,
        roomTypeName,
        totalAmount,
        hotelName,
        totalRoom,
      } = reservationRespData.data;
      
      mixPanelEvents.reservationSuccess(reservationRespData,totalCost)
      navigate(
        `/success${removeLogoutOption ? "/?device=mobile" : "/"}`,
        {
          state: {
            reservationRespData: reservationRespData,
            totalCost: totalCost,
            costSItems: costSItems,
            reservationStatus: reservationStatus,
            UpdatedTaxArary,
            totalTax,
          },
      });
    }
    if (reservationStatus === false) {
      console.log("status after failure", status);
      mixPanelEvents.reservationFailed()
      navigate(
        `/failure${removeLogoutOption ? "/?device=mobile" : "/"}`,
        { state: { reservationStatus: reservationStatus } }
      );
    }
  }, [reservationStatus]);


  return (
    <Collapse in={open} timeout={2000} className="overflow-hidden">
      {reservationStatus === false ? (
        ""
      ) : (
        <Swal status={status} dispatchMetod={setStatus} />
      )}

      {<SavedCards open={openCardOptions} />}
      <div>
        <h2 className="mt-[27px] text-xs font-normal leading-4 text-black uppercase text-center">
          ENTER GUEST INFORMATION
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={formFieldInitialState}
          validationSchema={payWithCardValidationSchema(isCardSelected)}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="mt-6">
              <SetFormField values={values} />
              <div className="flex space-x-4 ">
                <Input
                  name={"firstName"}
                  type={"text"}
                  placeholder={"FIRST NAME"}
                  // redStar={!existFirstName && '*'}
                  disableField={userData?.firstName ? true : false}
                  value={values.firstName}
                />

                <Input
                  name={"lastName"}
                  type={"text"}
                  placeholder={"LAST NAME"}
                  // redStar={!existLastName && '*'}
                  disableField={userData?.lastName ? true : false}
                  value={values.lastName}
                />
              </div>

              <Input
                type={"email"}
                placeholder={"EMAIL ADDRESS"}
                name={"email"}
                // redStar={!existEmail && '*'}
                disableField={userData?.email ? true : false}
                value={values.email}
              />

              <div className="flex space-x-4 ">
                <div>
                  <PhoneInput
                    name={"countryCodeForMobileNumber"}
                    country={"us"}
                    value={values.countryCodeForMobileNumber}
                    searchable={true}
                    onChange={(countryCode) => {
                      countryCodeForMobileNumberChangeHandler(
                        countryCode,
                        setFieldValue
                      );
                    }}
                    inputProps={{
                      readOnly: true,
                      style: {
                        width: "100px",
                        height: "42px",
                        fontSize: "16px",
                        borderRadius: "0",
                      },
                    }}
                  />
                </div>
                <div className="w-full">
                  <div className="mb-[29px] w-full relative">
                    <PatternFormat
                      type={"tel"}
                      placeholder={"000-000-0000"}
                      format={"###-###-####"}
                      name={"mobileNoWithoutCountryCode"}
                      className="block w-full h-[42px] placeholder-center px-4 text-black bg-white rounded-none border border-gray-400 focus:outline-none appearance-none"
                      onChange={(event) =>
                        setFieldValue(
                          "mobileNoWithoutCountryCode",
                          event.target.value
                        )
                      }
                      disabled={
                        userData?.mobileNoWithoutCountryCode ? true : false
                      }
                      value={userData?.mobileNoWithoutCountryCode || ""}
                    />
                    <ErrorMsg name={"mobileNoWithoutCountryCode"} />
                  </div>
                </div>
              </div>
              {!isUsrProfileNotFoundGpay && (
                <>
                  {(isSavedCards && !isCardSelected) || !isSavedCards ? (
                    <>
                      <h3 class="mb-[27px] text-xs font-normal leading-4 text-black uppercase text-center">
                        ENTER PAYMENT INFORMATION
                      </h3>

                      <Input
                        type={"text"}
                        placeholder={"NAME ON CARD"}
                        name={"name"}
                        value={values.name}
                      />

                      <div className="mb-[29px] w-full relative">
                        <PatternFormat
                          type={"text"}
                          placeholder={"XXXX-XXXX-XXXX-XXXX"}
                          format={"####-####-####-####"}
                          name={"card"}
                          className="block w-full h-[42px] placeholder-center px-4 text-black bg-white rounded-none border border-gray-400 focus:outline-none appearance-none"
                          onValueChange={(event) =>
                            setFieldValue("card", event.value)
                          }
                        />
                        <ErrorMsg name={"card"} />
                      </div>

                      <div className="flex space-x-4 ">
                        <div className="w-full relative">
                          <PatternFormat
                            type={"text"}
                            placeholder="MM/YY"
                            format={"##/##"}
                            name={"expiryDate"}
                            className="block w-full h-[42px] placeholder-center px-4 text-black bg-white rounded-none border border-gray-400 focus:outline-none appearance-none"
                            onChange={(event) =>
                              setFieldValue("expiryDate", event.target.value)
                            }
                            value={values.expiryDate}
                          />

                          {/* <DatePicker
                            placeholderText="MM/YY"
                            name="expiryDate"
                            selected={expiryDate}
                            onChange={(date) => {
                              expiryDateChangeHandler(date, setFieldValue);
                            }}
                            dateFormat="MM/yy"
                            minDate={new Date()}
                            showMonthYearPicker
                            className="block w-full h-[42px] placeholder-center px-4 text-black bg-white border border-gray-400  focus:outline-none"
                            popperClassName="w-64"
                            calendarClassName="bg-white text-gray-800 rounded-md shadow-md"
                            monthClassName={() => "text-[15px]"}
                          /> */}
                          <ErrorMsg name={"expiryDate"} />
                        </div>

                        <div className="relative w-full">
                          <Input
                            value={values.cvv}
                            type={isCvvVisible ? "text" : "password"}
                            placeholder={"CVV"}
                            name={"cvv"}
                            onChange={(e) => {
                              if (!/^[0-9]*$/.test(e.target.value)) {
                              } else {
                                setFieldValue("cvv", e.target.value);
                              }
                              console.log(typeof e.target.value);
                            }}
                          />
                          <button
                            type="button"
                            className="absolute top-0 right-0 flex items-center mt-[11px] mr-2 bg-white pl-2"
                            onClick={() => {
                              setIsCvvVisible(!isCvvVisible);
                            }}
                          >
                            {isCvvVisible ? (
                              <EyeIcon className="w-5 h-5" />
                            ) : (
                              <EyeOffIcon className="w-5 h-5" />
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="flex space-x-4 ">
                        <div className="w-full relative">
                          <Select
                            aria-label="country"
                            options={options}
                            name="country"
                            value={values.country}
                            onChange={(e) => {
                              setFieldValue("country", e);
                            }}
                            styles={customStyles}
                          />
                          <ErrorMsg name={"country"} />
                        </div>

                        <Input
                          name={"zipCode"}
                          type={"text"}
                          placeholder={"ZIP CODE"}
                          value={values.zipCode}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}
              {/* {!isUsrProfileNotFoundGpay && ( */}
              <div className="mt-12 mb-7">
                {totalCost > 0 ? (
                  <Button
                  type={"submit"}
                  className={"bg-black text-white"}
                  name={!loading && "Complete Booking"}
                  spanClassName={"font-normal"}
                  loadingThreeDots={loading && loadingThreeDots()}
                />
                ) : (
                  <Button
                    type={"button"}
                    className={"bg-black text-white cursor-not-allowed"}
                    name={!loading && "Complete Booking"}
                    spanClassName={"font-normal"}
                    loadingThreeDots={loading && loadingThreeDots()}
                  />
                )}
              </div>
              {/* )} */}
            </Form>
          )}
        </Formik>
      </div>
    </Collapse>
  );
};

export default PayWithCard;
