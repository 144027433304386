// ROOM DESCRIPTION CONTENT A
const roomDescA = [
  {
    title: "CONVENIENCE",
    subData: [
      {
        title: "Complimentary Wi-Fi",
      },
      {
        title: "Daily Housekeeping",
      },
      {
        title: "In-room Safe (Laptop Compatible)",
      },
      {
        title: "Desk",
      },
      {
        title: "Ergonomic Workspace",
      },
      {
        title: "Tabletop Charging Ports",
      },
      {
        title: "Iron/Ironing Board",
      },
      {
        title: "Free Local Calls",
      },
      {
        title: "Sofa (Suites Only)",
      },
    ],
  },
  {
    title: "IN ROOM REFRESHMENTS",
    subData: [
      {
        title: "Free Bottled Water",
      },
      {
        title: "Coffee/Tea Maker",
      },
      {
        title: "Honor Bar",
      },
    ],
  },
  {
    title: "BEDDING",
    subData: [
      {
        title: "Premium Bedding",
      },
      {
        title: "Pillowtop Mattress",
      },
    ],
  },
  {
    title: "CLIMATE CONTROL",
    subData: [
      {
        title: "Air Conditioning ",
      },
    ],
  },
  {
    title: "BATHROOM FEATURES",
    subData: [
      {
        title: "Bathrobes and Slippers",
      },
      {
        title: "Premium Bath Products",
      },
      {
        title: "Hair Dryer",
      },
    ],
  },
  {
    title: "IN-ROOM ENTERTAINMENT",
    subData: [
      {
        title: "55-inch Flat-screen TV",
      },
      {
        title: "Apple TV",
      },
      {
        title: "Premium TV Channels",
      },
      {
        title: "Cable TV Service",
      },
      {
        title: "iPod Docking Station",
      },
    ],
  },
  {
    title: "HOTEL AMENITIES",
    subData: [
      {
        title: "Free WiFi",
      },
      {
        title: "Free Self Parking",
      },
      {
        title: "Electric Car Charging Stations",
      },
      {
        title: "Dry Cleaning Service",
      },
      {
        title: "Scan and Photocopy Services",
      },
      {
        title: "24-hour Front Desk",
      },
      {
        title: "Luggage Storage",
      },
      {
        title: "HDTVs in Common Areas",
      },
    ],
  },
];

// ROOM DESCRIPTION CONTENT B
const roomDescB = roomDescA;

export const nestPalaltoDec = { roomDescA, roomDescB };
