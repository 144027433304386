import { Modal } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { CloseButton } from "../../commonElements/button";
import { useLocation } from "react-router-dom";

function HotelModal({ isOpen, handleClose = () => { }, allHotels = [] }) {

  const handleRedirect = (hotel) => {
    const newUrl = new URL(hotel?.bookingEngineURL)
    newUrl.search = window.location.search
    window.location.replace(newUrl)
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div
        onClick={handleClose}
        className="flex items-center justify-center h-screen"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="px-[16px] py-[20px] max-w-[465px] bg-white "
        >
          <div className="text-center text-[16px] relative leading-[19.2px] text-[#F41095] mb-[16px] w-full">
            SELECT HOTEL
            <div className="absolute right-0 top-0 mt-[-11px]">
              <CloseButton handleClose={handleClose} />
            </div>
          </div>
          <div className="space-y-[20px]">
            {allHotels.map((hotel, index) => (
              <div
                key={index}
                className="bg-white shadow-md overflow-hidden flex items-center p-[10px] gap-2"
              >
                <img
                  src={`https://res.cloudinary.com/shashigroup/image/upload/f_auto,q_auto,w_150/${hotel?.hotelImage[0]?.image[0]}`}
                  alt={hotel.hotelName}
                  className="w-[64px] h-[46px] object-cover"
                />
                <div className="ml-4 flex-1 text-black">
                  <div className="text-[14px] leading-[16.8px]">
                    {hotel.hotelName}
                  </div>
                  <div className="text-[10px] leading-[12px] mt-[5px] font-light">
                    {hotel.address}
                  </div>
                </div>
                <button onClick={()=>handleRedirect(hotel)} className="bg-black text-white w-[59px] h-[21.22px] text-center pt-[2px] text-[10px] leading-[12px]">
                  Select
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HotelModal;
