import React, { Fragment, useEffect, useMemo } from "react";
import { CloseButton } from "../../commonElements/button";
import { setHandleOpen } from "../rooms/roomSlice";
import { useDispatch, useSelector } from "react-redux";
import { BackDrop } from "../../commonElements";
import { shashiHotelDec } from "./shashiRoomDesc";
import { motion, AnimatePresence } from "framer-motion";
import { Abtest } from "../../mixpanel";
import { nestPalaltoDec } from "./nestpalaltoDec";

const RoomDescription = (open) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setHandleOpen(false));
  };
  const isOpen = useSelector((state) => state.room.isHandleOpen);

  const dynamicDisc = useMemo(() => {
    const domain = window.location.hostname;
    if (domain.includes("thenestpaloalto")) {
      return nestPalaltoDec;
    } else if (domain.includes("shashihotel")) {
      return shashiHotelDec;
    } else {
      return shashiHotelDec;
    }
  }, [shashiHotelDec, nestPalaltoDec]);

  return (
    <Abtest
      VariantA={
        <BackDrop handleClose={handleClose} open={isOpen}>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0px,10px,0px)" }}
              animate={{ opacity: 1, transform: "translate3d(0px,0px,0px)" }}
              transition={{ duration: 0.2, delay: 0.2 }}
              onClick={(e) => e.stopPropagation()}
              className="w-full p-6 m-auto bg-white max-w-[45rem] text-black text-justify shadow-md relative mx-5"
            >
              <div className="right-0 absolute mr-4 -mt-2">
                <CloseButton handleClose={handleClose} />
              </div>
              <div className="bg-red-400 mt-8" />
              <div
                className={`grid  md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 xl:gap-8 lg:gap-[1.50rem] md:gap-4 sm:gap-4 xs:gap-4 overflow-y-scroll h-[75vh]`}
              >
                {dynamicDisc.roomDescB?.map((room, index) => (
                  <div>
                    <h1 className="text-black">{room?.title}</h1>
                    <div className="xl:py-5 xl:pr-4 lg:py-4 lg:pr-4 md:py-4 md:pr-4 sm:py-4 sm:pr-4 xs:py-2 xs:pr-4 ">
                      {room?.subData.map((item, index) => (
                        <p
                          key={item.title + index}
                          className="text-black font-light text-sm xl:py-1 lg:py-1  md:py-1  sm:py-[0.50rem] xs:py-1"
                        >
                          {item?.title}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </BackDrop>
      }
      VariantB={
        <BackDrop handleClose={handleClose} open={isOpen}>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0px,10px,0px)" }}
              animate={{ opacity: 1, transform: "translate3d(0px,0px,0px)" }}
              transition={{ duration: 0.2, delay: 0.2 }}
              onClick={(e) => e.stopPropagation()}
              className=" w-full max-w-[500px] pt-2 pl-[40px] sm:pl-[60px] m-auto bg-white text-black text-justify shadow-md relative mx-5 "
            >
              <div className="right-0 absolute mr-2 ">
                <CloseButton handleClose={handleClose} />
              </div>
              <div className=" mt-[40px]" />
              <div className={` overflow-y-scroll h-[75vh] pr-3`}>
                {dynamicDisc.roomDescA?.map((room, index) => (
                  <div className="sm:mb-[20px] mb-[16px]">
                    <h1 className="text-black sm:mb-[5px] mb-[3px]">
                      {room?.title}
                    </h1>
                    <ul>
                      {room?.subData.map((item, index) => (
                        <li
                          key={item.title + index}
                          className="text-black text-left font-light text-sm sm:py-1 whitespace-pre-wrap"
                        >
                          {item?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </BackDrop>
      }
    />
  );
};

export default RoomDescription;
