import React, { Fragment, useEffect, useState } from "react";
import './style.css'
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { makePayment } from "../costSummary/api";
import {
  costSummarySelector,
  setGuestInfo,
  setGuestProfileToForm,
  setOpenPayInfo,
  setPaymentMethod,
  setPaymentMode,
  setStatus,
  setUserProfileNotFoundGPay,
} from "../costSummary/costSummarySlice";
import { calendarSelector } from "../calender/calenderSlice";
import { roomSelector } from "../rooms/roomSlice";
import { RatePlan_Category } from "../../config";
import { stripeSelector } from "./stripeSlice";
import { IconGooglePay } from "../../icons";
function PaymentWithStripe({queryDetails}) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const { totalCost, amount, isChecked, formFieldInitialState } =
    useSelector(costSummarySelector);
  const {isDateSingleSelect} = useSelector(calendarSelector)
  const {isAndroid} = useSelector(stripeSelector)
  const {status,isRoomAvailable,selectedRatePlan,selectedRoom} = useSelector(roomSelector)
  const {
    email,
    lastName,
    firstName,
    countryCodeForMobileNumber,
    mobileNoWithoutCountryCode,
  } = formFieldInitialState;
  
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Shashi Hotel",
        amount: amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ["link", "browserCard"],
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
  }, [stripe, elements]);

  useEffect(() => {
    if (paymentRequest && amount) {
      paymentRequest.update({
        total: {
          label: "Shashi Hotel",
          amount: amount,
        },
      });
    }
  }, [paymentRequest, amount]);

  useEffect(() => {
    if (paymentRequest && totalCost) {
      paymentRequest.on("token", async (e) => {
        const { token, payerEmail, payerName, walletName, payerPhone } = e;
        const { id } = token;
        let tokenData = {
          card: "",
          currency: "usd",
          customer: "",
          description: "Reservation",
          source: id,
          sourceXml: "",
          amount: parseFloat(totalCost),
          queryDetails
        };
        
        const guestData = {
          firstName: firstName || payerName?.split(" ")[0] || "",
          lastName: lastName || payerName?.split(" ")[1] || "",
          email: email || payerEmail || "",
          countryCodeForMobileNumber: mobileNoWithoutCountryCode
            ? countryCodeForMobileNumber
            : payerPhone?.split(" ")[0] || "",
          mobileNoWithoutCountryCode:
            mobileNoWithoutCountryCode || payerPhone?.split(" ")[1] || "",
          phoneNo: mobileNoWithoutCountryCode
            ? countryCodeForMobileNumber + mobileNoWithoutCountryCode
            : payerPhone || "",
        };
        dispatch(setGuestProfileToForm(guestData));
         if (!guestData.firstName || !guestData.lastName || !guestData.email) {
          let message = "Please enter First Name, Last Name and Email ID";
          if (!guestData.firstName && guestData.lastName && guestData.email) {
            message = "Please enter First Name";
          } else if (
            guestData.firstName &&
            !guestData.lastName &&
            guestData.email
          ) {
            message = "Please enter Last Name";
          } else if (
            guestData.firstName &&
            guestData.lastName &&
            !guestData.email
          ) {
            message = "Please enter Email ID";
          } else if (
            !guestData.firstName &&
            !guestData.lastName &&
            guestData.email
          ) {
            message = "Please enter First Name and Last Name";
          } else if (
            !guestData.firstName &&
            guestData.lastName &&
            !guestData.email
          ) {
            message = "Please enter First Name and Email ID";
          } else if (
            guestData.firstName &&
            !guestData.lastName &&
            !guestData.email
          ) {
            message = "Please enter Last Name and Email ID";
          }

          dispatch(setUserProfileNotFoundGPay(true));
          dispatch(setOpenPayInfo(true));
          e.complete("fail");
          dispatch(
            setStatus({
              apiMessage: message,
              apiSuccess: false,
            })
          );
        } else {
          if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(guestData.email))){

          dispatch(setUserProfileNotFoundGPay(false));
          dispatch(setOpenPayInfo(false));
          dispatch(setGuestInfo(guestData));
          dispatch(setPaymentMethod(walletName));
          dispatch(setPaymentMode("stripe"));
          dispatch(makePayment(tokenData));
          e.complete("success");
          }else{
            dispatch(
              setStatus({
                apiMessage:"Please enter a valid email" ,
                apiSuccess: false,
              })
            );
            e.complete("fail");
          }
          
        }
      });

      return () => {
        if (paymentRequest) {
          paymentRequest.off("token");
        }
      };
    }
  }, [
    countryCodeForMobileNumber,
    mobileNoWithoutCountryCode,
    paymentRequest,
    totalCost,
    firstName,
    lastName,
    email,
  ]);

  function clickButtonElement(e) {
    if (!selectedRoom.length > 0 && !parseFloat(totalCost)?.length) {
      e.preventDefault();
      dispatch(
        setStatus({
          apiMessage: "Please select your preferred room to complete the reservation.",
          apiSuccess: false,
        })
      );
      
    }
    else if (!isRoomAvailable) {
      e.preventDefault();
      dispatch(
        setStatus({
          apiMessage:
            status?.apiMessage ||
            "The hotel is unavailable for the dates selected.",
          apiSuccess: false,
        })
      );
    }else if (
      selectedRatePlan?.ratePlanCategory == RatePlan_Category?.corp &&
      selectedRatePlan?.ratePlanCode == ""
    ) {
      e.preventDefault();
      dispatch(
        setStatus({
          apiMessage:"Please provide the Rate Plan details to proceed with your reservation.",
          apiSuccess: false,
        })
      );
       return
    } else if (isChecked && !isDateSingleSelect) {
      if (isAndroid) {
        // for android device -->>
        window.location.href = `inapp://initializeGPayOnAndroid?amount=${JSON.stringify(parseFloat(totalCost))}`;
      }
      return;
    } else {
      e.preventDefault();
      dispatch(
        setStatus({
          apiMessage: isDateSingleSelect
            ? "Your Arrival and Departure date cannot be same."
            : "Please review and agree to the Terms & Conditions.",
          apiSuccess: false,
        })
      );
    }
  }
  
    // FOR GOOGLE PAY FROM APP SIDE -->>
  window.getToken = (token, nameFromCard) => {
    const guestData = {
      firstName: firstName || nameFromCard?.split(" ")[0] || "",
      lastName: lastName || nameFromCard?.split(" ")[1] || "",
      email: email || "",
      countryCodeForMobileNumber: mobileNoWithoutCountryCode
        ? countryCodeForMobileNumber: "",
      mobileNoWithoutCountryCode: mobileNoWithoutCountryCode || "",
      phoneNo: mobileNoWithoutCountryCode
        ? countryCodeForMobileNumber + mobileNoWithoutCountryCode
        : "",
    };

    let tokenData = {
      card: "",
      currency: "usd",
      customer: "",
      description: "Reservation",
      source: token,
      sourceXml: "",
      amount: parseFloat(totalCost),
      queryDetails
    };

    if (!guestData.firstName || !guestData.lastName || !guestData.email) {
      let message = "Please enter First Name, Last Name and Email ID";
      dispatch(setUserProfileNotFoundGPay(true));
      dispatch(setOpenPayInfo(true));
      dispatch(
        setStatus({
          apiMessage: message,
          apiSuccess: false,
        })
      );
    } else {
      if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        dispatch(setUserProfileNotFoundGPay(false));
        dispatch(setOpenPayInfo(false));
        dispatch(setGuestInfo(guestData));
        dispatch(setPaymentMethod("googlePay"));
        dispatch(setPaymentMode("stripe"));
        dispatch(makePayment(tokenData));
      } else {
        dispatch(
          setStatus({
            apiMessage:"Please enter a valid email" ,
            apiSuccess: false,
          })
        );
       }
    }
  }

  return (
    <Fragment>
      {paymentRequest && (
        <PaymentRequestButtonElement
          className="mt-[19px] stripe-payment-request-button bg-black"
          onClick={clickButtonElement}
          options={{ paymentRequest }}
        />
      )}

      { isAndroid && 
        <button
          className="h-[40px] bg-black w-full flex justify-center items-center mr-[3px] mt-[19px]"
        onClick={clickButtonElement}
        ><IconGooglePay/></button>
      }
    </Fragment>
  );
}

export default PaymentWithStripe;
