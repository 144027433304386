import React, { Fragment, useEffect } from "react";
import { MeetingSection } from "../../features";
import { meetingApis_ } from "../../features/meeting/api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  meetingSelector,
  meetingSliceActions,
} from "../../features/meeting/api/slice";
function Index() {
  const dispatch = useDispatch();
  const { calendarChannels, meetingCategory } = useSelector(meetingSelector);

  useEffect(() => {
    dispatch(meetingApis_.findCalendarChannel());
  }, []);

  const handleChange = (e) => {
    dispatch(meetingSliceActions.setMeetingCategory(e.target.value));
  };

  return (
    <section className="flex flex-col w-full">
      <select
        className="
          ml-auto mr-[40px] w-[300px] 
          rounded-md p-3 shadow-md 
          border-none outline-none"
        name="meetingCategory"
        onChange={handleChange}
        value={meetingCategory}
      >
        <option value="MP">Meeting pod</option>
        <option value="NS">Napa Sonoma</option>
        <option value="N/S">Napa or Sonoma</option>
      </select>
      {calendarChannels?.length > 0 ? <MeetingSection /> : <Fragment />}
    </section>
  );
}

export default Index;
